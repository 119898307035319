import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  ReadOutlined,
  ShopOutlined,
  PhoneOutlined,
  BellOutlined,
  TeamOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/reservation" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/reservation",
    sidebar: {
      icon: <UserOutlined />,
      label: "예약 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "예약 내역",
        },
        component: loadable(() => import("../pages/reservation/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/reservation/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/report",
    sidebar: {
      icon: <UserOutlined />,
      label: "디테일링 리포트 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "리포트 리스트",
        },
        component: loadable(() => import("../pages/detailing/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/detailing/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/write/:id",
        component: loadable(() => import("../pages/detailing/ReportList")),
      },
    ],
  },
];
